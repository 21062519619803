import { render, staticRenderFns } from "./subscription-and-payment.vue?vue&type=template&id=c60e6510&scoped=true&"
import script from "./subscription-and-payment.vue?vue&type=script&lang=ts&"
export * from "./subscription-and-payment.vue?vue&type=script&lang=ts&"
import style0 from "./subscription-and-payment.vue?vue&type=style&index=0&id=c60e6510&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c60e6510",
  null
  
)

export default component.exports