
























import { Component, Vue } from 'vue-property-decorator'

import container from '@/components/page/page-container.vue'

@Component({
  components: {
    container,
  },
})
export default class extends Vue {
  goToRegisterPage(): void {
    window.open('https://platform.sweevy.be/register')
  }

  goToHowItWorks(): void {
    this.$router.replace({
      hash: 'how-it-works',
    })
  }
}
