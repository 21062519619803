














































import { Component, Vue } from 'vue-property-decorator'

import sectionElement from '@/components/page/page-section.vue'
import container from '@/components/page/page-container.vue'

@Component({
  components: {
    sectionElement,
    container,
  },
})
export default class extends Vue {
  whatYouCanDo = [
    {
      icon: 'professionals/connect',
      key: 'connect',
    },
    {
      icon: 'professionals/chat',
      key: 'chat',
    },
    {
      icon: 'professionals/tips',
      key: 'tips',
    },
    {
      icon: 'professionals/history',
      key: 'history',
    },
    {
      icon: 'professionals/exercises',
      key: 'exercises',
    },
  ]
}
