
























import { Component, Vue } from 'vue-property-decorator'

import sectionElement from '@/components/page/page-section.vue'
import container from '@/components/page/page-container.vue'
import listElement from '@/components/elements/list-element.vue'

@Component({
  components: {
    sectionElement,
    container,
    listElement,
  },
})
export default class extends Vue {
  goToRegisterPage(): void {
    window.open('https://platform.sweevy.be/register')
  }
}
