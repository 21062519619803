














































import { Component, Vue, Watch } from 'vue-property-decorator'

import sectionElement from '@/components/page/page-section.vue'
import container from '@/components/page/page-container.vue'

interface Testimonial {
  image: unknown
  category: string
  name: string
  quote: string
}

@Component({
  components: {
    sectionElement,
    container,
  },
})
export default class extends Vue {
  testimonials: Testimonial[] = [
    {
      // eslint-disable-next-line global-require
      image: require('@/assets/images/testimonials/jan-vdh.jpeg'),
      category: this.$t('testimonials[0].category') as string,
      name: this.$t('testimonials[0].name') as string,
      quote: this.$t('testimonials[0].quote') as string,
    },
    {
      // eslint-disable-next-line global-require
      image: require('@/assets/images/testimonials/dries-m.jpeg'),
      category: this.$t('testimonials[1].category') as string,
      name: this.$t('testimonials[1].name') as string,
      quote: this.$t('testimonials[1].quote') as string,
    },
    {
      // eslint-disable-next-line global-require
      image: require('@/assets/images/testimonials/anke-s.jpeg'),
      category: this.$t('testimonials[2].category') as string,
      name: this.$t('testimonials[2].name') as string,
      quote: this.$t('testimonials[2].quote') as string,
    },
  ]

  sliderEl: HTMLElement | null = null

  slideWidth = 0

  translateX = 0

  translateDx = 0

  startX = 0

  startY = 0

  isMouseDown = false

  requireTouch = false

  disableScrolling = false

  dragSpeed = 2

  requestAnimationFrame: ReturnType<typeof window.requestAnimationFrame> | null = null

  // nextSlideInterval: ReturnType<typeof window.setInterval> | null = null

  get style(): Record<string, string> {
    if (!this.sliderEl) {
      return {}
    }

    return {
      transform: `translate3d(${-this.translateDx}px, 0, 0)`,
    }
  }

  get isAtStart(): boolean {
    return this.translateX <= 0
  }

  get isAtEnd(): boolean {
    if (!this.sliderEl) {
      return false
    }

    return this.translateX >= this.sliderEl.scrollWidth - this.sliderEl.clientWidth
  }

  getClosestIndex(): number {
    if (!this.sliderEl) {
      return 0
    }

    const songWidth: number = this.sliderEl.scrollWidth / this.testimonials.length

    return -Math.abs(Math.round(this.translateX / songWidth))
  }

  @Watch('disableScrolling')
  onDisableScrollingChange(): void {
    document.body.style.overflow = this.disableScrolling ? 'hidden' : 'auto'
  }

  mounted(): void {
    document.addEventListener('mouseup', this.handleMouseUp)
    document.addEventListener('touchend', this.handleTouchEnd)
    window.addEventListener('resize', this.handleWindowResize)

    this.sliderEl = this.$refs.slider as HTMLElement
    this.slideWidth = this.sliderEl.children[0].clientWidth

    this.raf()

    // this.nextSlideInterval = setInterval(() => {
    //   if (!this.isMouseDown) {
    //     this.goToNextSlide()
    //   }
    // }, 6000)
  }

  beforeDestroy(): void {
    if (this.requestAnimationFrame) {
      cancelAnimationFrame(this.requestAnimationFrame)
    }

    // if (this.nextSlideInterval) {
    //   clearInterval(this.nextSlideInterval)
    // }

    document.removeEventListener('mouseup', this.handleMouseUp)
    document.removeEventListener('touchend', this.handleTouchEnd)
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize(): void {
    if (this.sliderEl) {
      this.slideWidth = this.sliderEl.children[0].clientWidth
    }
  }

  setIndex(index: number): void {
    this.translateX = -(this.slideWidth * index)
  }

  goToNextSlide(): void {
    const nextIndex = this.getClosestIndex() - 1

    if (nextIndex < -this.testimonials.length + 1) {
      this.setIndex(0)
    } else {
      this.setIndex(nextIndex)
    }
  }

  goToPrevSlide(): void {
    const prevIndex = this.getClosestIndex() + 1

    if (prevIndex > 0) {
      this.setIndex(-this.testimonials.length + 1)
    } else {
      this.setIndex(prevIndex)
    }
  }

  handleMouseDown({ clientX }: MouseEvent): void {
    this.isMouseDown = true
    this.startX = clientX + this.translateX / this.dragSpeed
  }

  handleMouseMove({ clientX }: MouseEvent): void {
    if (!this.isMouseDown) return

    this.translateX = (this.startX - clientX) * this.dragSpeed
    this.validateMovement()
  }

  handleTouchStart(e: TouchEvent): void {
    const { clientX, clientY } = e.touches[0]

    this.startX = clientX + this.translateX / this.dragSpeed
    this.startY = clientY
  }

  handleTouchMove(e: TouchEvent): void {
    const { clientX, clientY } = e.touches[0]

    // if (this.requireTouch) {
    //   return
    // }

    // if (Math.abs(clientX - this.startX) / Math.abs(clientY - this.startY) > 1) {
    //   this.disableScrolling = true
    // } else {
    //   this.requireTouch = true
    //   return
    // }

    this.translateX = (this.startX - clientX) * this.dragSpeed

    this.validateMovement()
  }

  handleMouseUp(): void {
    this.isMouseDown = false

    this.setIndex(this.getClosestIndex())
  }

  handleTouchEnd(): void {
    this.requireTouch = false
    this.disableScrolling = false

    this.setIndex(this.getClosestIndex())
  }

  validateMovement(): void {
    if (!this.sliderEl) {
      return
    }

    if (this.isAtStart) {
      this.translateX = 0
    }

    if (this.isAtEnd) {
      this.translateX = this.sliderEl.scrollWidth - this.sliderEl.clientWidth
    }
  }

  raf(): void {
    this.translateDx = this.lerp(this.translateDx, this.translateX, this.isMouseDown ? 0.18 : 0.15)
    this.translateDx = Math.floor(this.translateDx * 100) / 100

    this.requestAnimationFrame = requestAnimationFrame(this.raf)
  }

  lerp(a: number, b: number, n: number): number {
    return (1 - n) * a + n * b
  }
}
