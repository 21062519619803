












import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'

import hero from '@/components/index/hero-section.vue'
import mission from '@/components/index/mission-section.vue'
import solution from '@/components/index/solution-section.vue'
import professionals from '@/components/index/professionals-section.vue'
import subscriptionAndPayment from '@/components/index/subscription-and-payment.vue'
import clients from '@/components/index/clients-section.vue'
import testimonials from '@/components/index/testimonials.vue'

@Component({
  components: {
    hero,
    mission,
    solution,
    professionals,
    subscriptionAndPayment,
    clients,
    testimonials,
  },
  metaInfo: {
    title: 'Sweevy',
    meta: [
      {
        name: 'description',
        content: 'Sweevy biedt je een uniek online platform om je clienteel digitaal op te volgen.',
      },
    ],
  },
})
export default class extends Vue {
  @Watch('$route', { immediate: true })
  onRouteChange(to: Route, from: Route): void {
    // if to hash exists and from doesn't exist, or from doesn't have a hash
    // or if to has a hash and from has a hash, but they're not equal hashes
    // and the route is also not equal`c
    if (
      (to.hash && (!from || !from.hash)) ||
      (to.hash && from.hash && to.hash !== from.hash && to.path === from.path)
    ) {
      this.$nextTick(() => {
        window.scrollTo(0, (document.querySelector(to.hash) as HTMLElement).offsetTop)
      })
    }
  }
}
