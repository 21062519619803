








































































import { Component, Vue, Watch } from 'vue-property-decorator'

import sectionElement from '@/components/page/page-section.vue'
import container from '@/components/page/page-container.vue'
import listElement from '@/components/elements/list-element.vue'

enum TabTransition {
  PREV = 'tab-transition-prev',
  NEXT = 'tab-transition-next',
}

@Component({
  components: {
    sectionElement,
    container,
    listElement,
  },
})
export default class extends Vue {
  transition = TabTransition.NEXT

  currentTab = 0

  previousTab = -1

  totalTabs = 4

  isTransitioning = false

  isTransitioningTimeout: ReturnType<typeof setTimeout> | null = null

  @Watch('currentTab')
  onCurrentTabChange(): void {
    if (this.currentTab > this.previousTab) {
      this.transition = TabTransition.NEXT
    } else {
      this.transition = TabTransition.PREV
    }

    this.previousTab = this.currentTab

    this.isTransitioning = true

    if (this.isTransitioningTimeout) {
      clearTimeout(this.isTransitioningTimeout)
    }

    this.isTransitioningTimeout = setTimeout(() => {
      this.isTransitioning = false
    }, 700)
  }

  prevTab(): void {
    if (this.currentTab > 0 && !this.isTransitioning) {
      this.currentTab -= 1
    }
  }

  nextTab(): void {
    if (this.currentTab < this.totalTabs - 1 && !this.isTransitioning) {
      this.currentTab += 1
    }
  }

  goToPlayStore(): void {
    window.open('https://play.google.com/store/apps/details?id=be.sweevy.app', '_blank')
  }

  goToAppStore(): void {
    window.open('https://apps.apple.com/be/app/sweevy/id1571373462', '_blank')
  }
}
