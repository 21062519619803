



























import { Component, Mixins } from 'vue-property-decorator'

import sectionElement from '@/components/page/page-section.vue'
import container from '@/components/page/page-container.vue'

import translationMixin from '@/mixins/translation.mixin'

@Component({
  components: {
    sectionElement,
    container,
  },
})
export default class extends Mixins(translationMixin) {
  cards = [
    {
      icon: 'solution-cards/schemes',
    },
    {
      icon: 'solution-cards/progression',
    },
    {
      icon: 'solution-cards/database',
    },
    {
      icon: 'solution-cards/save-time',
    },
    {
      icon: 'solution-cards/contact',
    },
    {
      icon: 'solution-cards/variation',
    },
  ]

  goToRegisterPage(): void {
    window.open('https://platform.sweevy.be/register')
  }
}
